import React from "react"
import { graphql } from "gatsby"
import "./blog-post.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInner";
import {withStyles} from "@material-ui/core";
import * as PATHS from '../utils/paths'
import {joinPaths} from "../utils/index";

const blogPostStyles = theme => ({
    root: {},

    descriptionText: {
        color: theme.palette.text.secondary,
    }
});

const _BlogPostTemplate = ({ classes, data, pageContext, location }) => {
  const post = data.markdownRemark

  return (
    <Layout location={location}>
        <SEO
            location={location}
            title={post.frontmatter.pageTitle || post.frontmatter.title}
            description={post.frontmatter.seoDescription || post.frontmatter.description || post.excerpt}
            imagePath={joinPaths([PATHS.CDN, post.fields.collection, post.fields.slug, "hero.png"])}
      />
      <article>
          <header>
              <LayoutInner>
                  <h1
                      style={{
                          marginTop: 16,
                          marginBottom: 0,
                          marginLeft: 0,
                          marginRight: 0
                      }}
                  >
                      {post.frontmatter.title}
                  </h1>

                  <div className={classes.descriptionText}
                       style={{
                           fontSize: 22,
                           lineHeight: "32px",
                           marginTop: 8,
                       }}>{post.frontmatter.description}</div>
                  <div className="sans-serif"
                       style={{
                           marginTop: 8,
                           marginBottom: 16,
                       }}
                  >
                      {post.frontmatter.readTime} minute read
                  </div>
              </LayoutInner>

          </header>

            <img src={joinPaths([PATHS.CDN, post.fields.collection, post.fields.slug, "hero.png"])}
                 alt={post.frontmatter.featuredImageAltText}
                 style={{width: "100%", maxWidth: 1200}}
            />
            <div style={{
                marginBottom: 32,
            }}>
                <section
                    style={{marginTop: 32, paddingLeft: 16, paddingRight: 16}}
                    dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>

      </article>

    </Layout>
  )
}

const BlogPostTemplate = withStyles(blogPostStyles)(_BlogPostTemplate)
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      
      html
      fields {
        collection
        slug
      }
      frontmatter {
        title
        description
        seoDescription
        readTime
        featuredImageAltText
      }
    }
  }
`
